import * as React from 'react'
import Getintouch from '../components/GetinTouch'
import LatestUpdates from '../components/LatestUpdates'
import Seo from '../components/seo'
import Layout from '../components/layout'
import InfoGraphics from '../components/InfoGraphics'
import DullMoment from '../components/DullMoment'
import SportsBoulevard from '../components/SportsBoulevard'
import WhoWeAre from '../components/WhoWeAre'
import Guidelines from '../components/Guidelines'
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import WhoWeAreButton from '../components/WhoWeAreButton'
import ComboWrapperNews from '../components/ComboWrapperNews'
export default function Newsroom(pageData) {
  const data = pageData?.data
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  let seo = pageData?.pageContext?.node?.seo
  let translatedURI = pageData?.pageContext?.node?.translations[0]?.uri

    return (
        <>
        <Seo data={seo}/>
        <Layout translatedURI={translatedURI}>
        <WhoWeAre
            title={data.wpPage.pageSubtitle?.subtitle}
            name={data.wpPage?.title}
            description={data.wpPage.pageDescription?.pageDescription}
          />
          
          <SportsBoulevard data={data.wpPage.inTheNews.mainSliderNews}/>
          
          {(data?.allWpPost?.edges.length>1)?
          <ComboWrapperNews data={data}/>:''
          }
     <Getintouch data={data.wpPage.inTheNews.newsContactForm} locale={locale}/>
        </Layout>
        </>
    )
}

export const pageQuery = (graphql` query newsroomData($databaseId:Int) {
  allWpMediacoverage(sort: {fields: date, order: DESC}) {
    edges {
      node {
        mediaCoverage {
          externalLink
          hashtag
        }
        title
        language {
          id
          locale
        }
        excerpt
        date(formatString: "DD-MM-YYYY")
        featuredImage {
          node {
            altText
            localFile {
              publicURL
            }
          }
        }
       content
        slug
        uri
        translations {
          uri
        }
      }
    }
  }
    allWpPost(sort: {fields: date, order: DESC}) {
      edges {
        node {
          language {
            id
            locale
          }
          id
          title
          tags {
            nodes {
              name
              id
            }
          }
          excerpt
          date(formatString: "DD-MM-YYYY")
          featuredImage {
            node {
              altText
              localFile {
                publicURL
              }
            }
          }
          previewImage {
            previewImage {
              altText
              localFile {
                publicURL
              }
            }
          }
          slug
          uri
        }
      }
    }
    allWpTag {
      edges {
        node {
          id
          name
          language {
            id
            code
            locale
          }
        }
      }
    }
    
    allWpCategory {
      edges {
        node {
          id
          name
          language {
            id
            code
            locale
          }
        }
      }
    }
  wpPage(databaseId: {eq: $databaseId}) {
    id
    title
    pageSubtitle {
      fieldGroupName
      subtitle
    }
    content
    featuredImage {
      node {
        altText
        localFile {
          publicURL
        }
      }
    }
    pageDescription {
      pageDescription
    }
    inTheNews {
      newsContactForm
      mainSliderNews {
        ... on WpPost {
          id
          slug
          uri
          title
          excerpt
          previewImage {
            previewImage {
              altText
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
}`);
import React, { useState, useMemo, useEffect, useContext } from "react"
import "./updates.scss"
import "./pagination.scss"
import { createMarkup, string_translation } from "../../../utils"
import { Link, useStaticQuery, graphql } from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

export default function LatestMediaCoverage(props) {
  
  const [todos, setTodos] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [filterCompleted, setFilterCompleted] = useState("")
  const [filterCompletedCat, setFilterCompletedCat] = useState("")
  const [filterCompletedTag, setFilterCompletedTag] = useState("")

  const [active, setactive] = useState(false)
  const [hasactivefilter, sethasactivefilter] = useState(null)

  const {
    locale,
    originalUrl
  } = useContext(TranslateUrlsContext)

  const { stringTranslations } = useStaticQuery(graphql`
    query {
      stringTranslations {
        latest_ar:translateString(language: AR, string: "LATEST UPDATES")
        latest_en:translateString(language: EN, string: "LATEST UPDATES")
        search_ar:translateString(language: AR, string: "SEARCH")
        search_en:translateString(language: EN, string: "SEARCH")
        advanced_ar:translateString(language: AR, string: "ADVANCED")
        advanced_en:translateString(language: EN, string: "ADVANCED")
        placeholder_ar:translateString(language: AR, string: "NEWSROOM SEARCH PLACEHOLDER")
        placeholder_en:translateString(language: EN, string: "NEWSROOM SEARCH PLACEHOLDER")
  
        workzone_ar:translateString(language: AR, string: "الأعمال الإنشائية")
        workzone_en:translateString(language: EN, string: "Work Zone")

        learnmore_ar:translateString(language: AR, string: "اعرف اكثر")
        learnmore_en:translateString(language: EN, string: "Learn More")

        sbactivity_ar:translateString(language: AR, string: "فعاليات مجتمع المسار الرياضي ​")
        sbactivity_en:translateString(language: EN, string: "Sports Boulevard community activities")

        tag_ar:translateString(language: AR, string: "TAG")
        tag_en:translateString(language: EN, string: "TAG")
        category_ar:translateString(language: AR, string: "CATEGORY")
        category_en:translateString(language: EN, string: "CATEGORY")
        duration_ar:translateString(language: AR, string: "DURATION")
        duration_en:translateString(language: EN, string: "DURATION")
        cancel_ar:translateString(language: AR, string: "CANCEL")
        cancel_en:translateString(language: EN, string: "CANCEL")

        all_en:translateString(language: EN, string: "ALL")
        all_ar:translateString(language: AR, string: "ALL")
        article_en:translateString(language: EN, string: "ARTICLE")
        article_ar:translateString(language: AR, string: "ARTICLE")
        pressrelease_en:translateString(language: EN, string: "PRESSRELEASE")
        pressrelease_ar:translateString(language: AR, string: "PRESSRELEASE")

        global_en:translateString(language: EN, string: "GLOBAL")
        global_ar:translateString(language: AR, string: "GLOBAL")
        tech_en:translateString(language: EN, string: "TECH")
        tech_ar:translateString(language: AR, string: "TECH")
        sustainability_en:translateString(language: EN, string: "SUSTAINABILITY")
        sustainability_ar:translateString(language: AR, string: "SUSTAINABILITY")
        art_en:translateString(language: EN, string: "ART")
        art_ar:translateString(language: AR, string: "ART")
        media_coverage_ar:translateString(language: AR, string: "تغطية إعلامية")
        media_coverage_en:translateString(language: EN, string: "Media Coverage")
        newest_en:translateString(language: EN, string: "NEWEST")
        newest_ar:translateString(language: AR, string: "NEWEST")
        oldest_en:translateString(language: EN, string: "OLDEST")
        oldest_ar:translateString(language: AR, string: "OLDEST")

        highlight_en:translateString(language: EN, string: "NO RESULT HIGHLIGHT")
        highlight_ar:translateString(language: AR, string: "NO RESULT HIGHLIGHT")
        text1_en:translateString(language: EN, string: "NO RESULT TEXT 1")
        text1_ar:translateString(language: AR, string: "NO RESULT TEXT 1")
        text2_en:translateString(language: EN, string: "NO RESULT TEXT 2")
        text2_ar:translateString(language: AR, string: "NO RESULT TEXT 2")
      }
    }
  `)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalTodos, setTotalTodos] = useState(0)
  const todosPerPage = 6
  const career = props.data.allWpMediacoverage.edges
  let newtodos = []
  let tagsList = []
  let categoryList = []

  const parseDate = (datestring) => {
    let datebits = datestring.split("-");
    let newDate = new Date( datebits[2], datebits[1] - 1, datebits[0]);
    return (newDate.getTime()/1000)
  }

  career.map((item, index) => {
    if (item.node.language.locale.indexOf(locale) !== -1) {
      return newtodos.push(item)
    }
  })

 

  useEffect(() => {
    setTodos(newtodos)
  }, [])

  const pageNumbers = []

  for (let i = 1; i <= Math.ceil(totalTodos / todosPerPage); i++) {
    pageNumbers.push(i)
  }

  const todosData = useMemo(() => {
    let computedTodos = todos

    if (searchTerm) {
      computedTodos = computedTodos.filter(todo =>
        todo?.node?.title?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    }

    if (filterCompletedTag != "" && filterCompletedTag != null && filterCompletedTag != undefined) {
      computedTodos = computedTodos.filter(
        todo => todo.node.tags.nodes[0]?.id === filterCompletedTag
      )
    }

    if (filterCompletedCat != "" && filterCompletedCat != null && filterCompletedCat != undefined) {
      computedTodos = computedTodos.filter(
        todo => todo.node.categories?.nodes[1]?.name === filterCompletedCat
      )
    }
    
    if (filterCompleted === "newest") {
      computedTodos = [...computedTodos].sort((a, b) =>
      parseDate(b.node.date) - parseDate(a.node.date)
      )
    }
    if (filterCompleted === "oldest") {
      computedTodos = [...computedTodos].sort((a, b) =>
      parseDate(a.node.date) - parseDate(b.node.date)
      )
    }

    setTotalTodos(computedTodos.length)

    //Current Page slice
    return computedTodos.slice(
      (currentPage - 1) * todosPerPage,
      (currentPage - 1) * todosPerPage + todosPerPage
    )
  }, [todos, currentPage, searchTerm, filterCompleted,filterCompletedCat,filterCompletedTag])

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber)

  const onNext = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1)
    }
  }
  const onPrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }
  const resetFilter = () => {
    setSearchTerm("")
    setFilterCompleted("")
    setFilterCompletedCat("")
    setFilterCompletedTag("")
    setCurrentPage(1)
  }
  let textInput = React.createRef()

  const toggleActiveBtn = (e) => {
    if (hasactivefilter !== e?.currentTarget) {
      const btnsTip = document.querySelectorAll(".anchor")
      btnsTip.forEach(btnTip => {
        btnTip.classList.remove("selected")
      })
      e.currentTarget.classList.add("selected")
      sethasactivefilter(e.currentTarget)
    }
    else{
      const filterTarget = e?.currentTarget
      sethasactivefilter(filterTarget)
      filterTarget.classList.toggle("selected")
      !filterTarget.classList.contains("selected") && resetFilter()
    }
    
  }
  const handleKeyDown = event => {
    if (event.key === "Enter" && textInput.current.value !== "") {
      setSearchTerm(textInput.current.value)
      setCurrentPage(1)
    }
  }
  const handleEmpty = () => {
    if (textInput.current.value == "") {
      resetFilter()
    }
  }
  const handleclick = () => {
    setSearchTerm(textInput.current.value)
    setCurrentPage(1)
  }
  let pagination = []
  if ( typeof document !== 'undefined' ) {
    pagination = document.querySelectorAll(".page-item")
  }
  let activepagination = null
  pagination.forEach(btnTip => {
    btnTip.addEventListener("click", e => {
      e.currentTarget.classList.add("active")
      if (activepagination !== null && activepagination !== e.currentTarget) {
        activepagination.classList.remove("active")
      }
      activepagination = e.currentTarget
    })
  })
  let btnsTip = []
  if ( typeof document !== 'undefined' ) {
    btnsTip = document.querySelectorAll(".anchor")
  }
  // let activeBtn = null
  // btnsTip.forEach(btnTip => {
  //   btnTip.addEventListener("click", e => {
  //     if (
  //       e.currentTarget.classList.contains("selected") &&
  //       activeBtn === e.currentTarget
  //     ) {
  //       e.currentTarget.classList.remove("selected")
  //     } else {
  //       e.currentTarget.classList.add("selected")

  //       if (activeBtn !== null && activeBtn !== e.currentTarget) {
  //         activeBtn.classList.remove("selected")
  //       }
  //     }

  //     activeBtn = e.currentTarget
  //   })
  // })
  let pag = newtodos.length >= 6 ? true : false
  console.log(todosData);
  return (
    <section className="last-updates-mediacoverage">
     
      <div className="container newsPage">
      <hr className="hrr"/>

      
        {todosData.length > 0 ? (
          <div className="col-group last-updates-posts">
            {todosData.map((item, index) => {
              return (
                <div className="col-dt-4 element" key={"n" + index}>
                  <Link target="_blank" to={item.node.mediaCoverage.externalLink} state={{ parenturl: originalUrl }}>
                  <div className="date-wrapper">
                      
                      { (item?.node?.title.length<71) ? (
       <div className="spotlight-title h2 mediaCoverage"> {item?.node?.title} </div>
      ) : (
        <div className="spotlight-title h2 mediaCoverage"> {item?.node?.title.substring(0, 70).replace(/<[^>]*>?/gm, '')}... </div>

      )}
                        
                      
                    </div>
                    <div className="date-wrapper">
                      <div className="date-tag">
                      {item.node.mediaCoverage.hashtag}
                      </div>
                      <hr className="date-hr" />
                      <div className="date">{item.node.date}</div>
                    </div>
                    <div>&nbsp;</div>
                    <div className="spotlight-img">
                      <img
                        src={
                          item.node.featuredImage?.node?.localFile
                            ?.publicURL
                        }
                        alt={item.node.featuredImage?.node?.altText || item?.node?.excerpt.substring(0, 147).replace(/<[^>]*>?/gm, '')}
                      />
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        ) : (
          <div className="no-result-div">
            <div className="result-wrapper">
              <div className="highlight">{string_translation(stringTranslations, "highlight", locale)}</div>
              <div className="text">
              {string_translation(stringTranslations, "text1", locale)}
              </div>
              <div className="text">
              {string_translation(stringTranslations, "text2", locale)}
              </div>
            </div>
          </div>
        )}

        {pag ? (
          <div className="grid-pagination">
            <ul className="custom-pagination">
              <li>
                <a onClick={onPrevious}>
                  <svg
                    width="6"
                    height="9"
                    viewBox="0 0 6 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.06495 4.98826C5.16042 4.89291 5.21411 4.76355 5.21423 4.62862V4.37076C5.21267 4.23612 5.15919 4.10729 5.06495 4.01112L1.57709 0.530048C1.51338 0.465817 1.42666 0.429688 1.3362 0.429688C1.24573 0.429688 1.15901 0.465817 1.0953 0.530048L0.613519 1.01183C0.54969 1.07437 0.51372 1.15997 0.51372 1.24933C0.51372 1.33869 0.54969 1.42429 0.613519 1.48683L3.63316 4.49969L0.613519 7.51255C0.549288 7.57625 0.513159 7.66297 0.513159 7.75344C0.513159 7.84391 0.549288 7.93063 0.613519 7.99433L1.0953 8.46933C1.15901 8.53356 1.24573 8.56969 1.3362 8.56969C1.42666 8.56969 1.51338 8.53356 1.57709 8.46933L5.06495 4.98826Z"
                      fill="#3D3935"
                      stroke="#3D3935"
                      stroke-width="0.5"
                    ></path>
                  </svg>
                </a>
              </li>
              {pageNumbers.map(number => (
                <li
                  key={"t" + number}
                  className={`page-item ${
                    currentPage === number ? "active" : ""
                  }`}
                >
                  <a
                    href="javascript:void(0)"
                    className="pagination "
                    onClick={() => paginate(number)}
                  >
                    {number}
                  </a>
                </li>
              ))}
              <li>
                <a onClick={onNext}>
                  <svg
                    width="6"
                    height="9"
                    viewBox="0 0 6 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.06495 4.98826C5.16042 4.89291 5.21411 4.76355 5.21423 4.62862V4.37076C5.21267 4.23612 5.15919 4.10729 5.06495 4.01112L1.57709 0.530048C1.51338 0.465817 1.42666 0.429688 1.3362 0.429688C1.24573 0.429688 1.15901 0.465817 1.0953 0.530048L0.613519 1.01183C0.54969 1.07437 0.51372 1.15997 0.51372 1.24933C0.51372 1.33869 0.54969 1.42429 0.613519 1.48683L3.63316 4.49969L0.613519 7.51255C0.549288 7.57625 0.513159 7.66297 0.513159 7.75344C0.513159 7.84391 0.549288 7.93063 0.613519 7.99433L1.0953 8.46933C1.15901 8.53356 1.24573 8.56969 1.3362 8.56969C1.42666 8.56969 1.51338 8.53356 1.57709 8.46933L5.06495 4.98826Z"
                      fill="#3D3935"
                      stroke="#3D3935"
                      stroke-width="0.5"
                    ></path>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </section>
  )
}

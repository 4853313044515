import React, { useEffect, useContext, useState } from "react"
import ReactModal from 'react-modal'
import "./comboWrapper.scss"
import { string_translation } from "../../../utils"
import {useStaticQuery, graphql} from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import SliderNavigation from '../SharedComponents/SliderNavigation'

// import Swiper core and required modules
import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

import { createMarkup } from "../../../utils"
import LatestMediaCoverage from "../LatestMediaCoverage"
import LatestUpdates from "../LatestUpdates"

export default function ComboWrapperNews(props) {

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const {stringTranslations} = useStaticQuery(graphql`query {
    stringTranslations {
      media_coverage_ar:translateString(language: AR, string: "تغطية إعلامية")
      media_coverage_en:translateString(language: EN, string: "Media Coverage")
      latest_ar:translateString(language: AR, string: "LATEST UPDATES")
      latest_en:translateString(language: EN, string: "LATEST UPDATES")
    }
  }`)



  let hash="#latest";
  if (typeof window !== 'undefined') {
    try{
  if(sessionStorage.getItem('hash')){
    hash=sessionStorage.getItem('hash');
  }
}catch(ex){
        
}
}
  try{
  if (window.location.hash) {
    hash=window.location.hash;
  }
  }catch(err){
    
  }

  useEffect(
    () => {
      if (hash) {
         setActiveTab(hash)
      }
    }
  )

  function setActiveTab(hashurl){
    var elements = document.getElementsByClassName("listhead")[0].getElementsByTagName("li");
      for (var i = 0; i < elements.length; i++) {
      if(elements[i].dataset.tab==hashurl){
        elements[i].classList.add("active");
      }else{
        elements[i].classList.remove("active");
      }
    }


    var elementsdown = document.getElementsByClassName("listbottom")[0].getElementsByTagName("div");
  
      for (var i = 0; i < elementsdown.length; i++) {
        if(elementsdown[i].id!=""){
      if('#'+elementsdown[i].id==hashurl){
        elementsdown[i].classList.add("active");
      }else{
        elementsdown[i].classList.remove("active");
      }
    }
    }
  }
  function updatehash(hash){
    if (typeof window !== 'undefined') {
      try{
      sessionStorage.setItem('hash', hash);
      }catch(ex){
        
      }
    }
    
  var arabic = document.querySelectorAll(".smail-menu-item,.arabic");
    try{
     for ( let i=0 ; i < arabic.length ; i++) {
      var elem = arabic[i];
       if(elem.childNodes){
       const url = elem.childNodes[0].href;
       const urlObj = new URL(url);
        urlObj.hash = hash;
        const result = urlObj.toString();
       elem.childNodes[0].href=result;
       }

      }
    }catch(aaa){

    }
  }
  return (
    <>
      <section className="combo-wrapper">
        <div className="container">
          <div className="combo-box-tab"> 
            <ul className="tabs tabs-list h2 listhead" data-controls="#newstab">
              <li data-tab="#latest" onClick={(e) => updatehash('#latest')}   className={hash == '#latest'? 'active': '' }>
                {string_translation(stringTranslations, "latest", locale)}
              </li>
              <li data-tab="#media_coverage" onClick={(e) => updatehash('#media_coverage')} className={hash == '#media_coverage'? 'active': '' }>
                {string_translation(stringTranslations, "media_coverage", locale)}
              </li>
             
            </ul>
          </div>
          <div className="tab-container listbottom" id="newstab">
            <div  id="latest" className={hash == '#latest'? 'active resizing': 'resizing' }>
             <LatestUpdates data={props.data} />
           
            </div>
            <div className={hash == '#media_coverage'? 'active resizing': 'resizing' } id="media_coverage">
            <LatestMediaCoverage data={props.data} />
            </div>
          
          </div>
        
        </div>
      </section>
    </>
  )
}
